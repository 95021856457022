/**======================================================================
=========================================================================
Template Name: Able Pro Admin Template
Author: Phoenixcoded
supports: https://phoenixcoded.ticksy.com
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */


// Theme Font
@import "themes/font/theme-font";

// theme scrollbar
@import "themes/plugin/perfect-scrollbar";

// main framework
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

// ng module
@import "react-module/react-module";

// bootstrap
@import "~bootstrap/scss/bootstrap";


// icon
@import "themes/font/next-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";
@import "themes/font/flaticon";

// bootstrap css
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/button";
@import "themes/bootstrap-overlay/radiobox-checkbox";
@import "themes/bootstrap-overlay/switches";
@import "themes/bootstrap-overlay/badge";

@import "themes/general";
@import "themes/generic";
@import "themes/plugin/animate";


// theme layouts
@import "themes/layouts/menu-lite";
@import "themes/layouts/menu-react";
@import "layout-dark";
@import "layout-rtl";

// Dashboard Widgets
@import "themes/dashboard/widget";

// custom plugin
@import "themes/plugin/sweatalert";
@import "themes/plugin/lightbox";
@import "themes/plugin/notification";
@import "themes/plugin/pnotify";
@import "themes/plugin/bar-rating";
@import "themes/plugin/rangeslider";
@import "themes/plugin/daterange";
@import "themes/plugin/tags-input";
@import "themes/plugin/maxlength";
@import "themes/plugin/wizard";
@import "themes/plugin/select2";
@import "themes/plugin/data-tables";

// Charts
@import "themes/plugin/highcharts";
@import "themes/plugin/peitychart";


// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/task-board";
@import "themes/pages/masonry-gallery";

// custom
@import "themes/custom";

.pcoded-inner-content {
}

.form-control{
  border-bottom: 1px solid #ced4da !important;
}

.swal2-container.swal2-shown{
  z-index: 10000;
}
.my-icon-remady {
  margin-left: -19px;
  margin-right: 14px;
  margin-top: -13px;  
}

.latest-update-box{
  .col{
    position: relative;
    padding-right: 40px;
    .map-action{
      position: absolute;
      right: 0;
      top: 2px; 
    }
  }
}

//for the calander
.eQmQXs{
  z-index: 1;
}

.latest-update-box{
  .col{
    h6{
      line-height: 24px;
      margin-bottom: 5px;
    }
    .text-muted{
      clear: both;
      font-size: 12px;
    }
    .badge{
      float: left;
      margin-bottom: 8px;
    }
  }
}

.user-profile-list table tbody tr:hover a, .user-profile-list table tbody tr:hover .badge-pill{
  color: #fff;
}

.user-profile-list table tbody tr:hover .badge-pill{
  background: rgba(255,255,255,0.1);
}

.dashboard-overview {
  .col{
    margin-bottom: 30px;
  }
}

.dashboard-overview {
  .card{
    height: 100%;
    margin-bottom: 0;
  }
}

.filter-bar {
  .Employee-field{
    width:170px;
  }
}

.mw-45{
  min-width: 45px;
}

.clock-in-wrapper{
  .w-100{
    margin-top: -15px;
  }
}
.my-cal-wrapper {
  div{
    z-index: 100;
  }
}

.my-spinner{
  position: absolute;
  left: 48%;
  top: 20px;
  z-index: 99;

  &.my-spinner-right{
    left: inherit;
    right: 12px;
    top:12px;
  }
}
.my-string-shorten{
  width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;  
}

.my-string-shorten-1{
  width:250px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;  
}

.cXcRCo {z-index: 2;}
.string-shorten-wrapper .icon-paperclip{
  position: absolute;
  right: 5px;
  top: 5px;
}

.string-shorten-wrapper .icon-paperclip:hover{
  cursor: pointer;
}

#data-table-logs td{
  padding: .5rem 0.5rem !important;
}
.active-language {
  background-color: #f8f9fa;
}
.border-bottom {
  transition: 0.5s;
}
.jodit_toolbar_btn-source, .jodit_toolbar_btn-brush, .jodit_toolbar_btn-table, .jodit_toolbar_btn-link, .jodit_toolbar_btn-eraser, .jodit_toolbar_btn-copyformat, .jodit_toolbar_btn-left , .jodit_toolbar_btn-fullsize, .jodit_toolbar_btn-dots  {
  display: none;
}

.app_dataTable tbody tr td , .web_dataTable tbody tr td {
  max-width: 200px;
}
th.sorting, th.sorting_asc, th.sorting_desc {
  background: none !important;
}

.topic-badge{
 position: absolute;
 top:0;
 right:0;
 background: navy;
}
.topic-title-label {
  padding-right: 50px;
}
.needs-validation > div:nth-child(2n) {
  background: #f2f2f2;
}
.topic-audio {
  width: 100%;
  border-radius: 20px;
}
.topic-date-label {
  margin-top:5px;
  font-size: 12px;
}

.topic-upload {
  margin-right: 7px;
  border: 0;
}
.topic-upload:hover {
  color: #fff;
  background: #474747;
  border: 0;
}
.chapter-headline{
  display: flex;
  line-height: 20px;
  justify-content: space-between;
}
.chapter-headline-plus{
  margin-left: 10px;
  margin-top:5px;
}
.action-on-chapter-list {
  width: 90px;
  text-align: right;
  position: relative;
  bottom: 30px;
}

.icon-flag-custom {
  width:25px;
}

.my_chap_list {
  width: calc(100% - 150px);
}

.upc-detail p{
  font-size: 15px !important;
}

.modal-content .form-group {
  padding: 0px 15px 0px 15px;
}
.img-radius-10 {
  border-radius: 10%
}
.image_carausal_wrapper {
  position: absolute;
    bottom: 0px;
    width: 5%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    right: 0px;
    height: 30px;
    padding-top: 5px;
}

#custom-addons7 {
  margin-right: 0px !important;
}

.my-table-view .table.table-sm td, .my-table-view .table.table-sm th {
  padding: 0.6rem 0rem !important;
}
.ft-17 {font-size: 17px !important;}

.my-icon {
  background-color: rgba(0, 0, 0, 0.3) !important;
  padding:10px;
  border-radius: 9px;
}

.my-certificate-badge {
  position: absolute;
    right: 3px;
    bottom: 0px;
    font-size: 54px;
}
.my-sample-product-images {
  width:80px !important;
}

.tooltip {
  z-index: 100000000; 
}

// .my-nav-li {
//   position: relative;
//     right: 200px;
// }

.profile-notification {right:0px !important;}

.my-table-view .table.table-sm td, .my-table-view .table.table-sm th {padding: .6rem 10px .6rem 0 !important;}

.drp-user button span.ml-4 {display:none !important;}  .my-table-view .my-string-shorten-1{ width:auto; overflow:visible; white-space: break-spaces;}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background: none!important;
    border-top: 1px solid #eaeaea;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.my-product-table td{
  word-break: break-word;
  white-space: inherit;
  max-width: 130px;
}
.react-datepicker-wrapper {
 display: block;
}
.cbd-table{
  tr,th,td{
    border: 1px solid #e2e5e8;
  }
  th{
    background: #ecf0f5;
    color: #37474f;
    text-transform: uppercase;
  }
  th,td{
    padding: 4px 8px;
  }
}
.project-order-modal{
  .modal-dialog{
    max-width: 1280px;
  }
}
